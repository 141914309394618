/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

import { chartExample1 } from "variables/charts.js";

export default function LandingPage() {
  
  const sectionHero = React.useRef(null);
  const sectionServices = React.useRef(null);

  const scrollHero = () => sectionHero.current.scrollIntoView()    
  const scrollServices = () => sectionServices.current.scrollIntoView()    

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    sectionHero.current.scrollTop = 0;
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  }, []);
  return (
    <>
      <ColorNavbar scrollHero={scrollHero} scrollServices={scrollServices} />
      <div className="wrapper" ref={sectionHero}>
        <div className="page-header">
          <img
            alt="..."
            className="path"
            src={require("assets/img/blob.png").default}
          />
          <img
            alt="..."
            className="path2"
            src={require("assets/img/path2.png").default}
          />
          <img
            alt="..."
            className="shapes triangle"
            src={require("assets/img/triunghiuri.png").default}
          />
          <img
            alt="..."
            className="shapes wave"
            src={require("assets/img/waves.png").default}
          />
          <img
            alt="..."
            className="shapes squares"
            src={require("assets/img/patrat.png").default}
          />
          <img
            alt="..."
            className="shapes circle"
            src={require("assets/img/cercuri.png").default}
          />
          <Container>
            <Row className="row-grid justify-content-between align-items-center text-left">
              <Col lg="6" md="6">
                <h1 className="text-white">
                  is your business ready for a<br />
                  <span className="text-white">digital <s>future</s> present?</span>
                </h1>
                <p className="text-white mb-3">
                  Cloud solutions are no longer just a idea or a vision of future.<br/>
                  Cloud solutions are an indispensable element of today's times.
                </p>
                <div className="btn-wrapper mb-3">
                  <p className="category text-success d-inline">
                    Check our cloud solutions
                  </p>
                </div>
              </Col>
              <Col lg="4" md="5">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("assets/img/g546.png").default}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <section className="section section-lg section-coins" ref={sectionServices}>
          <img
            alt="..."
            className="path"
            src={require("assets/img/path3.png").default}
          />
          <Container>
            <Row>
              <Col md="4">
                <hr className="line-info" />
                <h1>
                  Choose the services{" "}
                  <span className="text-info">that fits your needs</span>
                </h1>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/software.png").default}
                    />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="text-uppercase">Software</h4>
                        <span>as a Service</span>
                        <hr className="line-primary" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                        <ListGroupItem>REST services</ListGroupItem>
                        <ListGroupItem>API Frontends</ListGroupItem>
                        <ListGroupItem>Extract-Transform-Load</ListGroupItem>
                        <ListGroupItem>Order Management Systems</ListGroupItem>
                        <ListGroupItem>Content Management Systems</ListGroupItem>
                      </ListGroup>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center">
                  </CardFooter>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/platform.png").default}
                    />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="text-uppercase">Platform</h4>
                        <span>as a Service</span>
                        <hr className="line-success" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                        <ListGroupItem>Docker containers</ListGroupItem>
                        <ListGroupItem>Mail servers</ListGroupItem>
                        <ListGroupItem>SQL databases</ListGroupItem>
                        <ListGroupItem>No-SQL databases</ListGroupItem>
                        <ListGroupItem>RabbitMQ queues</ListGroupItem>
                      </ListGroup>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center">
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
        <DemoFooter />
      </div>
    </>
  );
}
